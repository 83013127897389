* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	text-decoration: none !important;
	font-family: 'Rubik', sans-serif;
	outline: 0 !important;
}

li {
	list-style: none;
}

a,
input[type=submit],
button {
	border: none;
	appearance: none;
	-webkit-appearance: none;
	transition: 0.4s;
}

.owl-item {
	float: left;
}

.read-more {
	display: table;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 17px;
	color: #d11b6f;
}

.read-more i {
	margin-left: 20px;
}

.read-more:hover {
	color: #322a71
}

h2 {
	font-size: 68px;
	font-weight: 600;
	color: #322a71;
	line-height: 65px;
	position: relative;
	width: 100%;
	position: relative;
	z-index: 2;
}

h2 strong {
	color: #d11b6f;
	font-weight: 600;
}

.subtitle {
	display: table;
	width: 100%;
	text-align: right;
	font-size: 27px;
	color: #999999;
	font-weight: 300;
	margin-bottom: 70px;
}

.subtitle strong {
	font-weight: 300;
	color: #322a71;
	position: relative;
	z-index: 2;
}

p {
	position: relative;
	z-index: 2;
	font-size: 17px;
	color: #575757;
	line-height: 30px;
	font-weight: 300;
}

button {
	background: none;
}

body.overflow {
	overflow: hidden;
}

header {
	display: table;
	width: 100%;
	padding: 40px 60px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
}

header .logo {
	float: left;
}

header .right-header {
	float: right;
}

header .right-header nav {
	float: left;
	display: table;
	margin-right: 30px;
}

header .right-header nav ul {
	display: table;
}

header .right-header nav ul li {
	float: left;
	position: relative;
}

header .right-header nav ul li a {
	color: #FFF;
	text-transform: uppercase;
	line-height: 70px;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 400;
	padding: 0 25px;
}

header .right-header nav ul li.current-menu-item a,
header .right-header nav ul li a:hover {
	color: #ff9933;
	text-decoration: underline !important;
}

header .right-header nav ul li ul {
	background: #FFF;
	border-radius: 5px;
	padding: 20px;
	width: 260px;
	position: absolute;
	top: 100%;
	left: 0;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(76, 76, 76, 0.16);
	-moz-box-shadow: 0px 0px 10px 0px rgba(76, 76, 76, 0.16);
	box-shadow: 0px 0px 10px 0px rgba(76, 76, 76, 0.16);
	display: none;
}

header .right-header nav ul li ul li {
	width: 100%;
	border-bottom: 1px solid #f2f2f2;
	margin: 0;
	padding: 0;
}

header .right-header nav ul li ul li a {
	color: #d11b6f;
	padding: 25px 0;
	line-height: 20px;
	display: table;
	width: 100%;
	text-transform: initial;
}

header .right-header nav ul li ul li:last-of-type {
	border-bottom: none;
}

header .right-header nav ul li:hover ul {
	display: table;
}


header .right-header .btn-cta {
	background: #ff9933;
	color: #FFF;
	text-transform: uppercase;
	line-height: 70px;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 400;
	border-radius: 70px;
	padding: 0 40px;
}

header .right-header .btn-cta i {
	margin-left: 10px;
}

header .right-header .btn-cta:hover {
	background: #d11b6f
}

header .right-header .btn-cta:before {
	border-color: #ff9933;
	border-radius: 70px;
}

header .right-header .btn-cta:hover:before {
	border-color: #d11b6f
}

.banner,
.banner-pages {
	display: block;
	width: 100%;
	overflow: hidden;
	height: 800px;
	border-bottom: 5px solid #ab1b65;
	position: relative;
	z-index: 2;
}

.banner-pages {
	height: auto;
	display: table;
}

.banner-pages.no-border {
	border-bottom: none;
}

.banner .owl-stage-outer,
.banner .owl-stage,
.banner .owl-item {
	height: 100%;
}

.banner .item-banner {
	display: table;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 3;
}

.banner .item-banner .container,
.banner-pages .container {
	height: 100%;
	position: relative;
}

.banner .item-banner .center-content-banner {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	width: 100%;
}

.banner-pages .center-content-banner {
	display: table;
	width: 100%;
	padding: 150px 0 60px;
}

.banner .item-banner .center-content-banner h2,
.banner-pages h2,
.banner-pages h1 {
	font-size: 5rem;
    font-weight: 500;
    line-height: 1.2;
	color: #FFF;
	margin-bottom: 40px;
}

.banner .item-banner .center-content-banner h2 strong,
.banner-pages h2 strong,
.banner-pages h1 strong {
	color: #d11b6f;
	font-weight: 500;
}

.banner .item-banner .center-content-banner .subtitle,
.banner-pages .subtitle {
	color: #FFF;
	font-size: 27px;
	margin-bottom: 20px;
	text-align: left;
}

.banner .item-banner .center-content-banner .subtitle strong,
.banner-pages .subtitle strong {
	color: #d11b6f;
}

.banner .item-banner .center-content-banner p,
.banner-pages p {
	font-size: 17px;
	color: #FFF;
	line-height: 30px;
	font-weight: 300;
}

.banner .owl-nav {
	position: absolute;
	width: 100%;
	padding: 0 70px;
	top: 50%;
	transform: translate(0, -50%);
}

.banner .owl-nav button {
	cursor: pointer;
	width: 40px;
	height: 40px;
	float: left;
	display: table;
	border: none;
}

.banner .owl-nav button.owl-prev {
	float: left;
	background: url(../images/prev-banner.png) center center no-repeat;
}

.banner .owl-nav button.owl-next {
	float: right;
	background: url(../images/next-banner.png) center center no-repeat;
}

.banner .owl-dots {
	display: none;
}

.banner:after {
	content: "";
	width: 17px;
	height: 27px;
	display: table;
	position: absolute;
	z-index: 10;
	left: 50%;
	transform: translate(-50%, 0);
	bottom: 40px;
	background: url(../images/scroll.png) center center no-repeat
}

.banner-cases {
	height: auto;
}

.banner-cases .center-content-banner {
	position: relative;
	padding: 200px 0 100px;
	transform: none;
	top: auto;
	display: table;
}

.banner-cases h1 {
	margin-bottom: 30px;
}

.banner-cases p strong {
	font-weight: 600;
}

.banner-cases a {
	float: left;
	line-height: 50px;
	padding: 0 35px;
	border: 2px solid #FFF;
	color: #FFF;
	font-weight: 600;
	border-radius: 50px;
	background: none;
	text-transform: uppercase;
	font-size: 15px;
	margin-top: 30px;
}

.banner-cases a:hover {
	background: #d11b6f;
	color: #FFF;
}

.banner-cases a i {
	margin-right: 5px;
}

.content-case {
	display: table;
	width: 100%;
	padding: 80px 0;
}

.content-case h2 {
	font-size: 70px;
	color: #d11b6f;
	font-weight: 600;
}

.content-case .subtitle {
	color: #322a71;
	margin-bottom: 35px;
	text-align: left;
}

.content-case .subtitle strong {
	color: #322a71;
	font-weight: 600;
}

.content-case img {
	display: table;
	width: 100%;
	height: auto;
}

.home-about {
	display: block;
	width: 100%;
	padding: 110px 0;
	position: relative;
}

.home-about:after {
	content: "SOBRE";
	position: absolute;
	color: #fafafa;
	font-weight: 900;
	font-size: 180px;
	left: 30px;
	bottom: 50px;
	z-index: 1;
}

.home-about .container {
	position: relative;
	z-index: 2;
}

.home-about .col-content {
	position: relative;
}

.home-about .col-content:before {
	content: "";
	width: 440px;
	height: 330px;
	display: table;
	border: 14px solid #f7f7f7;
	position: absolute;
	right: -60px;
	top: 0;
	z-index: 1;
}

.home-about h2 {
	margin-top: 50px;
}

.home-about .subtitle {
	display: table;
	width: 100%;
	text-align: right;
	font-size: 27px;
	color: #999999;
	font-weight: 300;
	margin-bottom: 70px;
}

.home-about .subtitle strong {
	font-weight: 300;
	color: #322a71;
	position: relative;
	z-index: 2;
}

.home-about .read-more {
	margin: 20px 0 0;
	float: left;
}

.home-about .read-more:hover {
	color: #322a71;
}

.home-about .carousel-about {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.home-about .carousel-about img {
	display: table;
	width: 100%;
	height: auto;
}

.home-about .carousel-about .owl-nav {
	display: none;
}

.home-about .carousel-about .owl-dots {
	display: table;
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 50px;
	text-align: center;
}

.home-about .carousel-about .owl-dots button {
	background: #FFF;
	cursor: pointer;
	float: none;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	margin: 0 10px;
	display: inline-table;
}

.home-about .carousel-about .owl-dots button.active {
	background: #d11b6f
}

.home-services {
	display: table;
	padding: 110px 0;
	background: url(../images/bg-servicos.png) center center no-repeat;
	width: 100%;
	background-size: cover;
}

.home-services .col-content {
	position: relative;
}

.home-services .col-content:before {
	content: "";
	width: 350px;
	height: 250px;
	display: table;
	border: 14px solid rgba(255, 255, 255, .1);
	position: absolute;
	right: -60px;
	top: 0;
	z-index: 1;
}

.home-services .col-content h2 {
	color: #FFF;
	margin-top: 50px;
}

.home-services .col-content .subtitle {
	color: #FFF;
	margin-bottom: 100px;
}

.home-services .col-content .subtitle strong {
	color: #d11b6f;
}

.home-services .col-content p {
	color: #FFF;
}

.home-services .col-content .read-more:hover {
	color: #FFF;
}

.home-services .card-service {
	display: table;
	width: 100%;
	margin: 40px 0;
	position: relative;
	z-index: 3;
}

.home-services .card-service .item-service {
	display: table;
	width: 50%;
	background: #FFF;
	position: relative;
	height: 300px;
	float: left;
	border-right: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}

.home-services .card-service .item-service:nth-of-type(2n) {
	border-right: 0;
}

.home-services .card-service .item-service:nth-last-child(2),
.home-services .card-service .item-service:last-of-type {
	border-bottom: 0;
}

.home-services .card-service .item-service .center-card {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	padding: 0 30px
}

.home-services .card-service .item-service .center-card h3 {
	font-size: 25px;
	color: #d11b6f;
	line-height: 25px;
	position: relative;
	padding-bottom: 23px;
	margin-bottom: 23px;
	transition: 0.4s;
}

.home-services .card-service .item-service .center-card h3:after {
	content: "";
	width: 30px;
	height: 2px;
	background: #322a71;
	position: absolute;
	left: 0;
	bottom: 0;
	display: table;
	transition: 0.4s;
}

.home-services .card-service .item-service .center-card p {
	color: #575757;
	margin-bottom: 0;
	transition: 0.4s;
}

.home-services .card-service .item-service:hover {
	background: #d11b6f;
	transform: scale(1.07);
	position: relative;
	z-index: 3;
	-webkit-box-shadow: 2px 2px 10px 0px rgba(255, 255, 255, 0.5);
	-moz-box-shadow: 2px 2px 10px 0px rgba(255, 255, 255, 0.5);
	box-shadow: 2px 2px 10px 0px rgba(255, 255, 255, 0.5);
}

.home-services .card-service .item-service:hover h3,
.home-services .card-service .item-service:hover p {
	color: #FFF;
}

.home-services .card-service .item-service:hover h3:after {
	background: #FFF;
}

.home-cases {
	display: block;
	width: 100%;
	padding: 110px 0;
	background: url(../images/bg-clientes.png) center top no-repeat;
}

.home-cases .col-content {
	position: relative;
}

.home-cases .col-content:before {
	content: "";
	width: 350px;
	height: 250px;
	display: table;
	border: 14px solid #FFF;
	position: absolute;
	right: 15px;
	top: 0;
	z-index: 1;
}

.home-cases .col-content h2 {
	margin-top: 50px;
}

.home-cases .col-content .subtitle {
	padding-right: 35px;
	margin-bottom: 0;
}

.home-cases .carousel-cases {
	display: block;
	position: relative;
	overflow: hidden;
	margin: 85px 0 0;
}

.home-cases .carousel-cases a {
	height: 360px;
	display: table;
	width: 100%;
	position: relative;
}

.home-cases .carousel-cases a .tag {
	display: table;
	width: 100%;
	font-size: 17px;
	color: #d11b6f;
	font-weight: 600;
}

.home-cases .carousel-cases a .title {
	display: table;
	width: 100%;
	color: #FFF;
	font-size: 27px;
	font-weight: 600;
}

.home-cases .carousel-cases a .bottom-align {
	position: absolute;
	bottom: 0;
	width: 100%;
	display: table;
	padding: 30px;
	z-index: 2;
}

.home-cases .carousel-cases a:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 1;
	background-image: linear-gradient(to top, rgba(50, 42, 113, .6), rgba(50, 42, 113, .5), rgba(50, 42, 113, .2), rgba(50, 42, 113, .1), rgba(50, 42, 113, 0));
}

.home-cases .carousel-cases .owl-nav {
	display: table;
	margin: 0 auto 0;
	padding-top: 35px;
}

.home-cases .carousel-cases .owl-nav button {
	cursor: pointer;
	margin: 0 10px;
	color: #d11b6f;
}

.home-cases .carousel-cases a:hover:before {
	background-image: linear-gradient(to top, rgba(209, 27, 111, .6), rgba(209, 27, 111, .5), rgba(209, 27, 111, .2), rgba(209, 27, 111, .1), rgba(209, 27, 111, 0));
}

.cases-page {
	background: url(../images/bg-parceria.png) center top no-repeat;
}

.cases-page .col-content:before {
	display: none;
}

.cases-page .col-content h2,
.cases-page .col-content .subtitle {
	width: 100%;
	text-align: center;
}

.wrap-testimonials {
	display: block;
	width: 100%;
	padding: 80px 0;
	background: url(../images/bg-depoimentos.png) center center no-repeat;
	background-size: cover;
	background-attachment: fixed;
}

.wrap-testimonials .carousel-testimonials {
	display: block;
	width: 750px;
	overflow: hidden;
	margin: 0 auto;
	padding: 0 70px;
	position: relative;
}

.wrap-testimonials .carousel-testimonials:before {
	content: "";
	z-index: 1;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(0, -50%);
	background: url(../images/aspas.png) center center no-repeat;
	width: 110px;
	height: 89px;
	display: table;
}

.wrap-testimonials .carousel-testimonials .owl-stage-outer {
	display: block;
	position: relative;
	overflow: hidden;
	z-index: 2;
}

.wrap-testimonials .carousel-testimonials .avatar {
	display: table;
	margin: 0 auto 15px;
	border-radius: 50%;
	overflow: hidden;
	width: 120px;
	height: 120px;
}

.wrap-testimonials .carousel-testimonials .avatar img {
	width: 100%;
	height: auto;
	display: table;
}

.wrap-testimonials .carousel-testimonials h2 {
	display: table;
	width: 100%;
	text-align: center;
	font-size: 27px;
	line-height: 27px;
	font-weight: 600;
	color: #d11b6f;
	margin-bottom: 0;
}

.wrap-testimonials .carousel-testimonials .function {
	display: table;
	width: 100%;
	text-align: center;
	font-size: 17px;
	font-weight: 500;
	color: #322a71;
	margin-bottom: 15px;
}

.wrap-testimonials .carousel-testimonials p {
	text-align: center;
}

.wrap-testimonials .carousel-testimonials p:last-of-type {
	margin-bottom: 0;
}

.wrap-testimonials .carousel-testimonials .owl-nav {
	display: table;
	margin: 0 auto 0;
	padding-top: 35px;
	z-index: 2;
}

.wrap-testimonials .carousel-testimonials .owl-nav button {
	cursor: pointer;
	margin: 0 10px;
	color: #d11b6f;
}

.home-blog {
	display: table;
	width: 100%;
	padding: 80px 0;
	background: url(../images/bg-blog.png) center top no-repeat;
}

.home-blog .col-content {
	position: relative;
}

.home-blog .col-content:before {
	content: "";
	width: 80%;
	height: 250px;
	display: table;
	border: 14px solid #f7f7f7;
	position: absolute;
	right: 15px;
	top: 0;
	z-index: 1;
}

.home-blog .col-content h2 {
	margin-top: 50px;
}

.home-blog .col-content .subtitle {
	position: relative;
	padding-right: 35px;
	margin-bottom: 0;
	z-index: 2;
}

.home-blog .featured-posts {
	margin-top: 85px;
	padding-bottom: 50px;
	margin-bottom: 50px;
	border-bottom: 1px solid #e5e5e5;
}

.home-blog .featured-posts .item-post {
	width: 100%;
}

.home-blog .featured-posts .item-post img {
	width: 100%;
	height: auto;
	display: table;
}

.home-blog .featured-posts .item-post span.date {
	font-size: 16px;
	color: #d11b6f;
	font-weight: 600;
	position: relative;
	padding-top: 10px;
	margin-top: 15px;
	float: left;
	width: 100%;
}

.home-blog .featured-posts .item-post span.date:before {
	content: "";
	width: 30px;
	height: 1px;
	display: table;
	position: absolute;
	top: 0;
	left: 0;
	background: #d11b6f
}

.home-blog .featured-posts .item-post h3 a {
	font-size: 27px;
	color: #322a71;
	font-weight: 600;
	line-height: 27px;
}

.home-blog .featured-posts .item-post h3 a:hover {
	color: #d11b6f;
}

.home-blog .list-posts {
	margin-bottom: 50px;
}

.home-blog .list-posts .item-post {
	width: 100%;
	display: table;
}

.home-blog .list-posts .item-post .image {
	width: 120px;
	display: table;
	float: left;
}

.home-blog .list-posts .item-post .image img {
	width: 100%;
	height: auto;
	display: table;
}

.home-blog .list-posts .item-post .text-post {
	float: left;
	padding-left: 15px;
	width: calc(100% - 120px);
}

.home-blog .list-posts .item-post .text-post .date {
	font-size: 16px;
	color: #d11b6f;
	font-weight: 600;
	float: left;
	width: 100%;
	margin-bottom: 5px;
}

.home-blog .list-posts .item-post .text-post h3 a {
	font-size: 17px;
	color: #322a71;
	font-weight: 600;
	line-height: 20px;
	display: table;
}

.home-blog .list-posts .item-post .text-post h3 a:hover {
	color: #d11b6f;
}

.home-blog .read-more {
	display: table;
	margin: 0 auto;
}

.contact {
	display: table;
	width: 100%;
	padding: 100px 0;
	background: url(../images/bg-contact.png) center center;
	background-size: cover;
}

.contact h2 {
	color: #FFF;
	margin-top: 50px;
}

.contact .col-content {
	position: relative;
}

.contact .col-content:before {
	content: "";
	width: 560px;
	height: 260px;
	display: table;
	border: 14px solid rgba(255, 255, 255, .1);
	position: absolute;
	right: -245px;
	top: 0;
	z-index: 1;
}

.contact .subtitle {
	color: #FFF;
	margin-bottom: 0;
}

.contact .subtitle strong {
	color: #d11b6f;
}

.contact .items-contact {
	display: table;
	margin-top: 72px
}

.contact .items-contact .item-contact {
	display: table;
	width: 100%;
	margin-bottom: 30px;
}

.contact .items-contact .item-contact:last-of-type {
	margin-bottom: 0;
}

.contact .items-contact .item-contact .title {
	font-size: 17px;
	color: #d11b6f;
	display: table;
	width: 100%;
	text-transform: uppercase;
	font-weight: 600;
}

.contact .items-contact .item-contact a,
.contact .items-contact .item-contact .text {
	font-size: 17px;
	color: #FFF;
	display: table;
	width: 100%;
	font-weight: 400;
}

.contact .items-contact .item-contact a i {
	margin-right: 10px;
}

.contact .items-contact .item-contact a.underline {
	text-decoration: underline !important;
}

.contact .items-contact .item-contact a:hover {
	color: #d11b6f;
}

.contact .box-selos {
	display: table;
	width: 100%;
}

.contact .box-selos img {
	float: left;
	margin-right: 20px;
}

.contact-work .items-contact {
	margin-top: 72px;
}

.contact .title-form {
	float: left;
	color: #FFF;
	font-size: 27px;
	font-weight: 600;
	margin-top: 60px;
	margin-bottom: 30px;
	width: 100%;
}

.contact .title-form strong {
	color: #d11b6f
}

.contact .subtitle-form {
	font-size: 17px;
	color: #FFF;
	font-weight: 300;
	width: 100%;
}

.contact form {
	display: table;
	width: 100%;
	margin: 110px 0 0;
}
.contact form option{
	color: #000;
}

.contact form .title-field {
	display: table;
	width: 100%;
	font-size: 15px;
	color: #d11b6f;
	font-weight: 400;
}

.contact form input[type=text],
.contact form input[type=email],
.contact form select,
.contact form textarea {
	width: 100%;
	height: 50px;
	border-radius: 5px;
	border: 1px solid rgba(255, 255, 255, .6);
	background: none;
	padding: 0 20px;
	color: #FFF;
}

.contact form textarea {
	height: 120px;
	padding: 10px 20px;
	resize: none;
}

.contact form button {
	float: right;
	height: 50px;
	padding: 0 35px;
	border: 2px solid #d11b6f;
	color: #d11b6f;
	font-weight: 600;
	border-radius: 50px;
	background: none;
	text-transform: uppercase;
	font-size: 15px;
}

.contact form button:hover {
	background: #d11b6f;
	color: #FFF;
}

.contact form button i {
	margin-left: 5px;
}

.contact-work {
	padding-top: 125px;
}

.custom-file {
	float: left;
	height: auto;
	width: 100%;
	padding: 10px 0px 0;
	margin-right: 20px;
	cursor: pointer;
}

.custom-file input {
	display: none;
}

.custom-file-control {
	float: left;
}

.custom-file-control::before {
	float: left;
	content: "Selecionar Arquivo";
	padding: 0 25px;
	border-radius: 7px;
	color: #FFF;
	font-weight: 600;
	font-size: 14px;
	margin-left: 0;
	background: #d11b6f;
	border: none;
	transition: 0.3s;
	margin-right: 10px;
}

.custom-file-control:hover::before {
	background: #d11b6f
}

.custom-file-control,
.custom-file-control:empty::after {
	content: "Arquivo: .PDF";
	float: left;
	line-height: 41px;
	font-size: 12px;
	color: #FFF;
	font-weight: 200;
}

.custom-file-control::after {
	float: right;
}

.services-list {
	display: table;
	width: 100%;
}

.services-list .item-service {
	display: table;
	padding: 90px 0;
	width: 100%;
}

.services-list .item-service:nth-of-type(2n) {
	background: url(../images/bg-depoimentos.png) center center no-repeat;
	background-size: cover;
}

.services-list .item-service h3 {
	width: 100%;
	text-align: right;
	font-size: 65px;
	font-weight: 600;
	line-height: 65px;
	color: #322a71
}

.services-list .item-service h3 strong {
	color: #d11b6f;
	font-weight: 600;
}

.services-list .item-service h3,
.services-list .item-service .subtitle {
	padding-right: 25px;
}

.services-list .item-service .subtitle {
	margin-bottom: 0;
}

.services-list .item-service .right-content {
	display: table;
	width: 100%;
	padding-left: 25px;
}

.services-list .item-service .right-content a {
	float: left;
	line-height: 56px;
	padding: 0 35px;
	border: 2px solid #d11b6f;
	color: #322a71;
	font-weight: 600;
	border-radius: 50px;
	background: none;
	text-transform: uppercase;
	margin-top: 15px;
	font-size: 15px;
}

.services-list .item-service .right-content a:hover {
	background: #d11b6f;
	color: #FFF;
}

.services-list .item-service .right-content a i {
	margin-left: 5px;
}

.services-list .item-service .col-md-7 {
	border-left: 1px solid #e3e3e3;
}

.service-content {
	display: table;
	width: 100%;
	padding: 80px 0;
}

.service-content h2 {
	font-size: 44px;
	line-height: 46px;
}

.service-content .subtitle {
	text-align: left;
	margin-bottom: 30px;
	line-height: 30px;
}

.service-content .btn-cases {
	display: table;
	width: 100%;
	background: #d11b6f;
	padding: 40px;
	position: relative;
	margin-top: 40px;
}

.service-content .btn-cases .left {
	color: #FFF;
	font-weight: 500;
	font-size: 25px;
	line-height: 26px;
	float: left;
	position: relative;
	z-index: 2;
}

.service-content .btn-cases .right {
	color: #FFF;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	float: right;
	text-transform: uppercase;
	padding: 17px 0;
	position: relative;
	z-index: 2;
}

.service-content .btn-cases .right i {
	margin-left: 5px;
}

.service-content .btn-cases:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 40%;
	background: #b31255;
	transition: 0.4s;
}

.service-content .btn-cases:hover:before {
	width: 100%;
}

.process {
	display: table;
	width: 100%;
	padding: 80px 0;
	background: url(../images/bg-depoimentos.png) center center no-repeat;
	background-size: cover;
}

.process h2 {
	font-size: 44px;
	line-height: 46px;
}

.process .subtitle {
	text-align: left;
	margin-bottom: 0;
}

.process .item-process {
	display: table;
	width: 100%;
	margin-top: 50px;
	padding-left: 135px;
	position: relative
}

.process .item-process h3 {
	display: table;
	width: 100%;
	font-weight: 600;
	font-size: 27px;
	color: #d11b6f;
	position: relative;
	padding: 0 0 15px;
	margin: 0 0 15px;
}

.process .item-process h3:after {
	content: "";
	width: 30px;
	height: 1px;
	display: table;
	position: absolute;
	left: 0;
	bottom: 0;
	background: #322a71;
}

.process .item-process .box-number {
	position: absolute;
	left: 0;
	top: 0;
	width: 100px;
	text-align: center;
	border: 5px solid #e6e6e6;
	padding: 20px 0
}

.process .item-process .box-number .rotulo {
	display: table;
	width: 100%;
	font-size: 17px;
	line-height: 18px;
	color: #322a71;
	font-weight: 300;
}

.process .item-process .box-number .number {
	font-size: 44px;
	line-height: 44px;
	display: table;
	width: 100%;
	font-weight: 800;
	color: #d11b6f;
}

.process img {
	width: 100%;
	height: auto;
	display: table;
	margin-top: 60px;
}

.menu-bar {
	width: 100%;
	display: table;
	background-image: linear-gradient(to right, #632165, #7e1f6a, #981c6b, #b21969, #d11b6f);
	position: relative;
	z-index: 99999;
}

.menu-bar nav {
	float: left;
}

.menu-bar nav>ul {
	display: table;
	margin: 0;
}

.menu-bar nav li {
	float: left;
	margin-right: 30px;
	position: relative;
}

.menu-bar nav li a {
	line-height: 60px;
	font-size: 16px;
	display: table;
	color: #FFF;
	font-weight: 300;
	float: left;
}

.menu-bar nav li a:hover {
	text-decoration: underline !important;
}

.menu-bar nav li span {
	cursor: pointer;
	margin-left: 10px;
	float: left;
	line-height: 60px;
	font-size: 16px;
	display: table;
	color: #FFF;
}

.menu-bar nav li span i {
	line-height: 60px;
	display: table;
}

.menu-bar nav li ul {
	display: none;
	width: 200px;
	position: absolute;
	top: 100%;
	background: #d11b6f;
	padding: 20px;
}

.menu-bar nav li ul li {
	display: table;
	width: 100%;
}

.menu-bar nav li ul li a {
	width: 100%;
	line-height: 20px;
	padding: 12px 0;
}

.menu-bar nav li:hover ul {
	display: table;
}

.menu-bar form {
	float: right;
	position: relative;
	background: rgba(0, 0, 0, .1);
	width: 260px;
}

.menu-bar form input[type=text] {
	height: 60px;
	width: calc(100% - 60px);
	border: none;
	background: none;
	padding: 0 20px;
	float: left;
	font-size: 15px;
	color: #FFF;
	font-weight: 300;
}

.menu-bar form button {
	height: 60px;
	width: 60px;
	color: #FFF;
	background: none;
	border: none;
	float: left;
}

.menu-bar form ::-webkit-input-placeholder {
	color: #FFF;
}

.menu-bar form ::-moz-placeholder {
	color: #FFF;
}

.menu-bar form :-ms-input-placeholder {
	color: #FFF;
}

.menu-bar form :-moz-placeholder {
	color: #FFF;
}

.wrap-materiais {
	display: table;
	padding: 80px 0;
	width: 100%;
}

.wrap-materiais .boxes-materiais {
	display: table;
	width: 100%;
}

.wrap-materiais .boxes-materiais .item-material {
	width: 50%;
	float: left;
	height: 450px;
	border: 1px solid #FFF;
	position: relative;
}

.wrap-materiais .boxes-materiais .item-material .center-content {
	position: absolute;
	display: table;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 40px;
}

.wrap-materiais .boxes-materiais .item-material .center-content .prefix {
	display: table;
	width: 100%;
	text-align: center;
	font-size: 16px;
	color: #d11b6f;
	font-weight: 300;
}

.wrap-materiais .boxes-materiais .item-material .center-content h2 {
	display: table;
	width: 100%;
	text-align: center;
	font-size: 39px;
	line-height: 40px;
	color: #FFF;
	font-weight: 300;
	margin: 10px 0;
}

.wrap-materiais .boxes-materiais .item-material .center-content p {
	width: 100%;
	text-align: center;
	color: #FFF;
}

.wrap-materiais .boxes-materiais .item-material .center-content .btn-cta {
	background: #ff9933;
	color: #FFF;
	text-transform: uppercase;
	line-height: 70px;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 400;
	border-radius: 70px;
	padding: 0 40px;
	display: table;
	margin: 0 auto;
}

.wrap-materiais .boxes-materiais .item-material .center-content .btn-cta i {
	margin-left: 10px;
}

.wrap-materiais .boxes-materiais .item-material .center-content .btn-cta:hover {
	background: #d11b6f
}

.wrap-pagination {
	display: table;
	margin: 50px auto 0;
}

.wrap-pagination a,
.wrap-pagination span {
	border: none !important;
	float: left;
	line-height: 40px;
	font-size: 16px;
	font-weight: 600;
	padding: 0 12px !important;
	color: #d11b6f;
	border-radius: 4px;
	text-align: center;
}

.wrap-pagination span.current {
	background: #d11b6f;
	color: #FFF;
}

.wrap-pagination .nextpostslink,
.wrap-pagination .previouspostslink {
	margin: 0 10px;
	border-radius: 50%;
	background: #f2f2f2;
	color: #a9a9a9;
	width: 40px;
	padding: 0 !important
}

.newsletter {
	display: table;
	width: 100%;
	padding: 50px 0;
	background: url(../images/bg-depoimentos.png) center center no-repeat;
	background-size: cover;
}

.newsletter h2 {
	font-weight: 300;
	font-size: 44px;
	line-height: 46px;
}

.newsletter h2 strong {
	color: #322a71;
	font-weight: 600;
}

.newsletter .subtitle {
	text-align: left;
	margin-bottom: 20px;
}

.newsletter form {
	display: table;
	width: 100%;
}

.newsletter form .title-field {
	display: table;
	width: 100%;
	font-size: 15px;
	color: #d11b6f;
	font-weight: 600;
}

.newsletter form input[type=text],
.newsletter form input[type=email] {
	width: 100%;
	height: 50px;
	border-radius: 5px;
	border: 1px solid #dcdbe2;
	background: none;
	padding: 0 20px;
	color: #4c4c4c;
}

.newsletter form button {
	float: right;
	height: 50px;
	width: 100%;
	border: 2px solid #d11b6f;
	color: #d11b6f;
	font-weight: 600;
	border-radius: 50px;
	background: none;
	text-transform: uppercase;
	font-size: 15px;
	margin-top: 22px;
}

.newsletter form button:hover {
	background: #d11b6f;
	color: #FFF;
}

.newsletter form button i {
	margin-left: 5px;
}

.clients-page {
	display: table;
	width: 100%;
	padding: 80px 0;
}

.clients-page .item-client {
	display: table;
	width: 100%;
	border: 1px solid #e6e6e6;
	margin-bottom: 30px;
}

.clients-page .item-client img {
	display: table;
	width: 100%;
	height: auto;
}

.box-cta {
	display: table;
	width: 100%;
	padding: 60px 50px;
	background: url(../images/bg-side-service.png) center center no-repeat;
	background-size: cover;
	position: relative;
}

.box-cta:before {
	border-color: #282157
}

.box-cta h3 {
	color: #FFF;
	font-size: 600;
	font-size: 27px;
	line-height: 30px;
	position: relative;
	padding-bottom: 20px;
	margin-bottom: 20px
}

.box-cta h3:after {
	content: "";
	width: 30px;
	height: 1px;
	background: #FFF;
	position: absolute;
	bottom: 0;
	left: 0;
	display: table;
}

.box-cta h3 strong {
	color: #d11b6f;
}

.box-cta p {
	font-size: 17px;
	color: #FFF;
	line-height: 30px;
	font-weight: 300;
}

.box-cta .btn-cta {
	background: #ff9933;
	color: #FFF;
	text-transform: uppercase;
	line-height: 70px;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 400;
	border-radius: 70px;
	padding: 0 40px;
}

.box-cta .btn-cta i {
	margin-left: 10px;
}

.box-cta .btn-cta:hover {
	background: #d11b6f
}

.box-cta .phones {
	display: table;
	width: 100%;
	margin-top: 25px;
	margin-bottom: 10px;
}

.box-cta .phones a {
	float: left;
	font-size: 20px;
	line-height: 20px;
	color: #ff9933;
	margin-right: 15px;
	padding-right: 15px;
	border-right: 2px solid #ff9933;
}

.box-cta .phones a:last-of-type {
	border-right: none;
	margin-right: 0;
	padding-right: 0;
}

.box-cta .phones a:hover {
	color: #d11b6f
}

.box-cta .mail {
	color: #FFF;
	font-size: 17px;
	font-weight: 300;
}

.box-cta .mail:hover {
	color: #d11b6f
}

.wrap-box-cta {
	display: table;
	width: 100%;
}

.wrap-box-cta .box-cta .btn-cta {
	width: 100%;
	text-align: center;
}

.wrap-box-cta .box-cta .phones {
	margin: 25px auto 15px;
	width: auto;
}

.wrap-box-cta .box-cta .mail {
	text-align: center;
	width: 100%;
	display: table;
}

.wrap-instagram {
	display: table;
	width: 100%;
	padding: 80px 0 50px;
	background: url(../images/digital.png) center top no-repeat;
}

.wrap-instagram h2 {
	display: table;
	width: 100%;
	text-align: center;
	font-size: 44px;
	line-height: 46px;
}

.wrap-instagram .subtitle {
	margin-bottom: 40px;
	display: table;
	width: 100%;
	text-align: center;
}

.wrap-instagram a {
	display: table;
	width: 100%;
	margin-bottom: 30px;
}

.wrap-instagram a img {
	width: 100%;
	height: auto;
}

.wrap-location {
	width: 100%;
	display: table;
	padding: 0 0 100px;
}

.wrap-location .col-content {
	position: relative;
}

.wrap-location .col-content:before {
	content: "";
	width: 350px;
	height: 250px;
	display: table;
	border: 14px solid #f7f7f7;
	position: absolute;
	right: 15px;
	top: 0;
	z-index: 1;
}

.wrap-location .subtitle {
	padding-right: 40px;
	margin-bottom: 0;
}

.wrap-location h2 {
	margin-top: 50px;
}

.wrap-location address {
	color: #d11b6f;
	font-weight: 600;
	font-size: 17px;
	line-height: 26px;
	float: left;
	margin-bottom: 0;
}

.wrap-location .btn-address {
	background: #e6e6e6;
	color: #666666;
	text-transform: uppercase;
	line-height: 50px;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 400;
	border-radius: 70px;
	padding: 0 30px;
	margin-left: 30px;
}

.wrap-location .btn-address i {
	margin-left: 10px;
}

.wrap-location .btn-address:hover {
	background: #d11b6f;
	color: #FFF;
}

.wrap-location iframe {
	width: 100%;
	height: 530px;
	margin-top: 70px;
}

.item-case {
	display: table;
	width: 100%;
	padding: 165px 0;
}

.item-case .prefix {
	display: table;
	width: 100%;
	font-weight: 600;
	font-size: 27px;
	color: #d11b6f;
	line-height: 27px;
}

.item-case h2 {
	color: #FFF;
	font-size: 44px;
	line-height: 44px;
	margin-bottom: 25px;
	padding-bottom: 25px;
	position: relative;
}

.item-case h2:after {
	content: "";
	width: 30px;
	height: 1px;
	display: table;
	position: absolute;
	left: 0;
	bottom: 0;
	background: #d11b6f;
}

.item-case p {
	color: #FFF;
}

.item-case a {
	float: left;
	line-height: 50px;
	padding: 0 45px;
	border: 2px solid #d11b6f;
	color: #d11b6f;
	font-weight: 600;
	border-radius: 50px;
	background: none;
	text-transform: uppercase;
	font-size: 15px;
}

.item-case a:hover {
	background: #d11b6f;
	color: #FFF;
}

.item-case a i {
	margin-left: 5px;
}

.results {
	display: table;
	padding: 80px 0;
	background: url(../images/bg-results.png) center center no-repeat;
	width: 100%;
	background-size: cover;
}

.results h2 {
	display: table;
	text-align: center;
	color: #d11b6f;
	width: 100%;
}

.results .subtitle {
	color: #FFF;
	text-align: center;
	margin-bottom: 60px;
}

.results .subtitle strong {
	color: #FFF;
	font-weight: 600;
}

.results .number {
	display: table;
	width: 100%;
	text-align: center;
	font-size: 70px;
	color: #d11b6f;
	font-weight: 600;
	line-height: 72px;
	display: table;
}

.results p {
	padding: 0 20px;
	color: #FFF;
	width: 100%;
	text-align: center;
}

.wrap-blog {
	display: table;
	width: 100%;
	padding: 80px 0;
}

.item-blog {
	display: table;
	width: 100%;
	margin-bottom: 60px;
}

.item-blog:last-of-type {
	margin-bottom: 0
}

.item-blog img {
	width: 100%;
	display: table;
	height: auto
}

.item-blog .info {
	font-size: 17px;
	color: #d11b6f;
	line-height: 17px;
	font-weight: 300;
}

.item-blog .info strong {
	font-weight: 600;
}

.item-blog .info a {
	text-decoration: underline !important;
	color: #d11b6f;
}

.item-blog h2 {
	margin: 6px 0
}

.item-blog h2,
.item-blog h2 a {
	font-size: 27px;
	line-height: 32px;
	color: #322a71;
	font-weight: 600;
}

aside {
	width: 100%;
}

aside .box-widget {
	display: table;
	width: 100%;
	margin-bottom: 60px;
}

aside img {
	width: 100%;
	height: auto;
}

aside h3 {
	width: 100%;
	padding: 30px;
	text-align: center;
	background: #e6e6e6;
	font-size: 24px;
	color: #808080;
	margin-bottom: 30px;
}

aside .item-blog h2,
aside .item-blog a {
	font-size: 17px;
	line-height: 20px;
	font-weight: 600;
}

aside .item-blog p {
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 0;
}

aside .item-blog {
	margin-bottom: 35px;
	padding-bottom: 35px;
	border-bottom: 1px solid #e5e5e5;
}

aside .item-blog:last-of-type {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

.blog-single {
	display: table;
	width: 100%;
}

.top-share {
	display: table;
	width: 100%;
	padding-bottom: 20px;
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 40px;
}

.top-share a {
	margin-left: 14px;
	width: 30px;
	line-height: 30px;
	border-radius: 3px;
	color: #FFF;
	text-align: center;
	font-size: 14px;
}

.top-share a.facebook {
	background: #306098;
}

.top-share a.twitter {
	background: #26c4f2;
}

.top-share a.whatsapp {
	background: #43d854;
}

.blog-single .post-infos {
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 1px solid #e5e5e5;
	display: table;
}

.blog-single .post-infos h1 {
	font-size: 44px;
	line-height: 50px;
	color: #322a71;
	font-weight: 300;
}

.blog-single .post-infos h1 strong {
	font-weight: 600;
	color: #322a71;
}

.blog-single .post-infos .info {
	font-size: 17px;
	color: #d11b6f;
	line-height: 17px;
	font-weight: 300;
	margin-top: 15px;
	float: left;
}

.blog-single .post-infos .info strong {
	font-weight: 600;
}

.blog-single .post-infos .info a {
	text-decoration: underline !important;
	color: #d11b6f;
}

.blog-single .post-content {
	display: table;
	width: 100%;
}

.blog-single .post-content img {
	max-width: 100%;
	height: auto;
	display: table;
	margin-bottom: 20px;
}

.blog-single .post-content img.featured {
	width: 100%;
}

.blog-single .post-content h2 {
	font-size: 27px;
	line-height: 35px;
	color: #575757;
	font-weight: 600;
}

.blog-single .post-content h3 {
	font-size: 24px;
	color: #575757;
	font-weight: 600;
}

.blog-single .post-content h4 {
	font-size: 21px;
	color: #575757;
	font-weight: 600;
}

.blog-single .post-content ul {
	display: table;
	margin: 0 0 20px;
}

.blog-single .post-content ul li {
	font-size: 17px;
	color: #575757;
	line-height: 30px;
	font-weight: 300;
	display: table;
}

.blog-single .post-content ul li:before {
	content: "•";
	margin-right: 6px;
}

.blog-single .post-content p a {
	color: #d11b6f;
	text-decoration: underline !important;
}

.blog-single .post-content p strong {
	font-weight: 600
}

.blog-single .post-content blockquote {
	font-style: italic;
	padding: 20px;
	background: #f2f2f2;
}

.blog-single .post-content blockquote p:last-of-type {
	margin-bottom: 0;
}

.fb_iframe_widget_fluid_desktop,
.fb_iframe_widget_fluid_desktop span,
.fb_iframe_widget_fluid_desktop iframe {
	width: 100% !important;
}

.blog-single .post-content .alignleft {
	float: left;
	margin-right: 20px;
	margin-bottom: 20px;
	max-width: calc(100% - 20px)
}

.blog-single .post-content .alignright {
	float: right;
	margin-right: 20px;
	margin-bottom: 20px;
	max-width: calc(100% - 20px)
}

.blog-single .post-content .aligncenter {
	display: table;
	margin: 0 auto;
	float: none;
}

.blog-single .post-content iframe {
	max-width: 100%;
	height: 300px;
}

.content-404 {
	display: table;
	padding: 150px 0;
	width: 100%;
}

.content-404 img {
	display: table;
	margin: 0 auto 20px;
}

.content-404 h2 {
	display: table;
	width: 100%;
	text-align: center;
	font-size: 50px;
	line-height: 55px;
}

.content-404 .subtitle {
	width: 100%;
	text-align: center;
	margin-bottom: 0;
}

.content-404 .subtitle strong {
	color: #d11b6f;
}

footer {
	display: table;
	width: 100%;
	padding: 20px 0;
	margin-top: -70px;
	position: relative;
	z-index: 3
}

footer .center-footer {
	display: table;
	margin: 0 auto;
}

footer .center-footer span {
	width: 280px;
	float: left;
	text-align: right;
	color: rgba(255, 255, 255, .5);
	font-size: 15px;
	font-weight: 300;
	margin: 0 30px
}

footer .center-footer span:last-of-type {
	text-align: left;
}

footer .center-footer span a {
	color: rgba(255, 255, 255, .5);
	font-size: 15px;
	font-weight: 300;
	margin-right: 15px;
}

footer .center-footer span a:hover {
	color: #FFF;
}

footer.footer2 span,
footer.footer2 span a {
	color: #8d89ab;
}

footer.footer2 span a:hover {
	color: #d11b6f
}

.side-socials {
	position: fixed;
	left: -130px;
	top: 50%;
	transform: translate(0, -50%) rotate(-90deg);
	z-index: 99999999;
	width: 325px;
}

.side-socials span {
	font-weight: 300;
	color: #666666;
	font-size: 14px;
	float: left;
	margin-left: 10px;
	line-height: 30px;
}

.side-socials span strong {
	font-weight: 600;
}

.side-socials a {
	float: left;
	width: 29px;
	line-height: 26px;
	border: 2px solid;
	border-radius: 50%;
	text-align: center;
	font-size: 13px;
	margin: 0 10px;
	transform: rotate(90deg);
}

.side-socials a.facebook {
	border-color: #4363a2;
	color: #4363a2
}

.side-socials a.instagram {
	border-color: #e65e6a;
	color: #e65e6a
}

.side-socials a.linkedin {
	border-color: #1673b1;
	color: #1673b1;
}

.side-socials a i {
	display: table;
	line-height: 26px;
	width: 100%;
	text-align: center;
}

.wrap-comments {
	display: table;
	margin: 40px 0 0;
	width: 100%
}

div.wpcf7-validation-errors,
div.wpcf7-spam-blocked {
	border: none !important;
	padding: 10px !important;
	text-align: center;
	margin: 20px 0 0 !important;
	background: #F00;
	color: #FFF !important;
	font-weight: 600;
	text-transform: uppercase;
	float: left;
	width: 100%;
}

div.wpcf7-mail-sent-ok {
	border: none !important;
	padding: 10px !important;
	text-align: center;
	margin: 20px 0 0 !important;
	background: #398f14;
	color: #FFF !important;
	font-weight: 600;
	text-transform: uppercase;
	float: left;
	width: 100%;
}

.menu-bar .open-menu-bar {
	display: none;
}

.mobile-menu {
	display: none;
}

header .open-menu {
	display: none;
}

@media only screen and (max-width: 1500px) {
	header .logo img {
		width: 176px;
		display: table;
		height: auto;
	}

	header .right-header nav ul li a {
		padding: 0 17px;
		line-height: 60px;
	}

	header .right-header .btn-cta {
		line-height: 60px;
		padding: 0 20px;
	}

	header {
		padding: 40px 20px;
	}

	.banner .owl-nav {
		padding: 0 50px;
	}

	.banner .item-banner .center-content-banner {
		padding: 0 60px;
	}
}

@media only screen and (max-width: 1250px) {
	header .right-header nav ul li a {
		padding: 0 12px;
		font-size: 14px;
	}

	header .right-header .btn-cta {
		font-size: 13px;
	}
}

@media only screen and (max-width: 1100px) {
	header .right-header nav ul {
		display: none;
	}

	header .right-header .btn-cta {
		position: fixed;
		bottom: 15px;
		right: 15px;
		line-height: 50px;
	}

	.banner .owl-nav {
		display: none;
	}

	.side-socials {
		bottom: 15px;
		left: 50%;
		transform: none;
		top: auto;
		width: auto;
		max-width: 100%;
		padding: 5px 0;
	}

	.side-socials span {
		display: none;
	}

	.side-socials a {
		margin-left: 0;
		margin-right: 6px;
		transform: none;
		background: #FFF;
		width: 39px;
		line-height: 36px;
	}

	.side-socials a i {
		line-height: 35px;
	}

	.container {
		max-width: 100% !important;
	}

	.banner .item-banner .center-content-banner {
		padding: 0 15px !important;
	}

	.banner-pages .center-content-banner {
		padding: 140px 0 50px
	}

	header {
		padding: 40px 15px;
	}

	.banner .item-banner .center-content-banner h2,
	.banner-pages h2,
	.banner-pages h1 {
		font-size: 70px;
		line-height: 70px;
	}

	h2,
	.content-case h2 {
		font-size: 55px;
		line-height: 55px;
	}

	.services-list .item-service h3 {
		font-size: 40px;
		line-height: 42px;
	}

	.wrap-testimonials .carousel-testimonials {
		width: 100%;
	}

	.home-about:after {
		display: none;
	}

	.contact {
		background-color: #322a71;
		padding-bottom: 230px;
	}

	footer {
		margin-top: -210px;
	}

	footer .center-footer span {
		width: 100%;
		text-align: center !important;
		padding: 10px 0;
		margin: 0
	}

	.services-list .item-service .col-md-5,
	.services-list .item-service .col-md-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.services-list .item-service h3,
	.services-list .item-service .subtitle {
		padding-right: 0;
		text-align: left;
	}

	.services-list .item-service .subtitle {
		margin-bottom: 30px !important;
	}

	.services-list .item-service .col-md-7 {
		border: none;
	}

	.services-list .item-service .right-content {
		padding-left: 0;
	}

	.menu-bar {
		position: relative;
	}

	.menu-bar .open-menu-bar {
		line-height: 60px;
		font-size: 16px;
		display: table;
		color: #FFF;
		font-weight: 300;
		float: left;
	}

	.menu-bar nav>ul {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		background: #d11b6f;
		width: 100%;
		padding: 0 15px;
	}

	.menu-bar nav li span {
		display: none;
	}

	.menu-bar nav>ul>li {
		width: 100%;
	}

	.menu-bar nav>ul>li ul {
		position: relative;
		top: 0;
		width: 100%;
		display: table;
		padding: 0 0 0 20px
	}

	.menu-bar form {
		width: 200px;
	}

	header .right-header nav {
		margin-right: 0;
	}

	header .open-menu {
		display: table;
		color: #FFF;
		font-size: 25px;
		line-height: 60px;
	}

	.mobile-menu {
		width: 100%;
		display: block;
		height: 100%;
		position: fixed;
		overflow-x: hidden;
		overflow-y: scroll;
		padding: 60px 20px 20px;
		background: #322a71;
		top: 0;
		right: -100%;
		z-index: 9999999999;
		transition: 0.5s;
	}

	.mobile-menu.active {
		right: 0;
	}

	.mobile-menu ul,
	.mobile-menu ul li,
	.mobile-menu ul a {
		display: table;
		width: 100%;
	}

	.mobile-menu ul li a {
		font-size: 18px;
		font-weight: 600;
		line-height: 21px;
		padding: 20px 0;
		color: #FFF;
		border-bottom: 1px solid rgba(255, 255, 255, .1);
	}

	.mobile-menu ul li:last-of-type a {
		border: none;
	}

	.mobile-menu .close-menu {
		font-size: 18px;
		font-weight: 600;
		line-height: 21px;
		padding: 15px 0;
		color: #FFF;
		position: absolute;
		top: 10px;
		right: 20px;
	}

	.mobile-menu ul li ul {
		background: rgba(255, 255, 255, .1);
		padding: 0 20px;
	}
}

@media only screen and (max-width: 767px) {

	.home-about,
	.home-services,
	.home-cases,
	.home-blog,
	.services-list .item-service,
	.process,
	.service-content,
	.wrap-blog,
	.content-case {
		padding: 65px 0
	}

	.subtitle {
		text-align: left !important;
		margin-bottom: 40px !important;
	}

	.col-content:before {
		display: none !important;
	}

	.col-content h2 {
		margin-top: 0 !important
	}

	h1 br,
	h2 br {
		display: none;
	}

	.home-about .carousel-about {
		margin-top: 40px;
	}

	.home-services,
	.contact {
		background: #322a71
	}

	.home-cases,
	.home-blog {
		background: #FFF;
	}

	.home-cases .float-right {
		float: left !important;
	}

	.home-blog .featured-posts {
		margin-top: 30px;
	}

	.home-blog .featured-posts .item-post {
		margin-bottom: 40px;
	}

	.home-blog .list-posts .item-post {
		margin-bottom: 20px;
	}

	.contact form {
		margin-top: 40px;
	}

	.banner-pages h1 {
		margin-bottom: 10px;
	}

	.banner-pages .subtitle {
		margin-bottom: 20px !important;
	}

	.banner .item-banner .center-content-banner h2,
	.banner-pages h2,
	.banner-pages h1 {
		font-size: 50px;
		line-height: 50px;
	}

	.box-cta:before {
		display: none !important;
	}

	.box-cta {
		padding: 40px 30px;
	}

	.process .item-process {
		padding-left: 0;
		padding-top: 130px;
	}

	.box-cta {
		margin-top: 30px;
	}

	.box-cta .phones a {
		border: none !important;
		width: 100%;
		text-align: center;
		padding: 5px;
	}

	.box-cta .mail {
		width: 100%;
		text-align: center;
		display: table;
	}

	.box-cta .btn-cta {
		width: 100%;
		text-align: center;
		line-height: 60px;
	}

	.box-cta p,
	.box-cta h3 {
		width: 100%;
		text-align: center
	}

	.box-cta h3:after {
		left: 50%;
		transform: translate(-50%, 0)
	}

	.cases-page .col-content h2,
	.cases-page .col-content .subtitle {
		text-align: center !important;
		padding-right: 0
	}

	.home-cases .carousel-cases {
		margin-top: 55px;
	}

	.cases-page .float-right {
		float: none !important;
		margin: 0 auto;
		display: table;
	}

	.wrap-blog .item-blog .image {
		margin-bottom: 25px;
		display: table;
		width: 100%;
	}

	aside {
		margin-top: 40px;
	}

	.newsletter h2,
	.blog-single .post-infos h1,
	.item-case h2,
	.results h2 {
		font-size: 33px;
		line-height: 35px;
	}

	.newsletter .subtitle {
		margin-bottom: 20px !important;
		font-size: 23px !important;
	}

	.item-blog h2,
	.item-blog h2 a {
		font-size: 23px;
		line-height: 26px;
	}

	.item-case {
		padding: 100px 0
	}

	.wrap-box-cta .box-cta {
		margin-top: 0 !important;
	}

	.content-case img {
		margin-top: 20px;
	}

	.process img {
		display: none;
	}

	.results .subtitle {
		text-align: center !important;
	}

	.box-cta .btn-cta {
		padding: 0;
	}

	.box-cta.boxed {
		padding: 40px 15px;
	}

	.contact .box-selos img {
		margin-top: 10px;
	}

	.blog-single .post-content .alignleft,
	.blog-single .post-content .alignright {
		float: none;
		clear: both;
	}

	.wrap-materiais .boxes-materiais .item-material {
		width: 100%;
	}

	.wrap-materiais .boxes-materiais .item-material .center-content h2 {
		font-size: 25px;
		line-height: 30px;
	}

	.wrap-materiais .boxes-materiais .item-material .center-content {
		padding: 40px 20px;
	}

	.contact {
		padding-top: 130px;
	}
}

@media only screen and (max-width: 680px) {

	.banner .item-banner .center-content-banner h2,
	.banner-pages h2,
	.banner-pages h1,
	h1,
	h2 {
		font-size: 40px;
		line-height: 40px;
	}

	.banner .item-banner .center-content-banner .subtitle,
	.banner-pages .subtitle {
		font-size: 23px
	}

	.home-services .card-service .item-service .center-card h3 {
		font-size: 18px;
	}

	.home-services .card-service .item-service .center-card {
		padding: 0 20px;
	}

	.wrap-testimonials .carousel-testimonials {
		padding: 0;
	}

	.wrap-testimonials .carousel-testimonials:before {
		display: none;
	}

	.service-content .btn-cases:before {
		width: 50%;
	}

	.banner .item-banner .center-content-banner p br,
	.banner-pages p br {
		display: none;
	}
}

@media only screen and (max-width: 500px) {
	.home-services .card-service .item-service {
		width: 100%;
	}

	.box-cta .btn-cta {
		font-size: 12px;
	}

	.box-cta {
		padding: 40px 0
	}

	.side-socials{
		width: auto;
		max-width: 100%;
		right: 0;
		left: inherit;
	}
}