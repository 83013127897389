@import 'styles';

body {
    max-width: 100%;
    font-family: 'Rubik', sans-serif;
    font-size: 17px;
    color: #575757;
    font-style: normal;
    line-height: 30px;
    font-weight: 300;  
}

#header {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    color: #fff;
    z-index: 999999;
    padding: 0;
    font-size: 16px;

    #header-menu {
        display: block;

        ul.menu {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            padding: 0;
            margin: 0;
            list-style: none;
            text-transform: uppercase;
            font-weight: 400;

            li.menu-item {
                display: inline-block;
                text-align: center;
                position: relative;

                &.menu-item-has-children {
                    >a {
                        display: block;
                        position: relative;
                        padding-right: 2rem;

                        &::before {
                            position: absolute;
                            right: 6px;
                            top: 11px;
                            content: "\f078";
                            display: inline-block;
                            font: normal normal normal 14px/1 FontAwesome;
                            font-size: inherit;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                        }
                    }
                }

                a {
                    display: inline-block;
                    color: #fff;
                    text-decoration: none;
                    padding: 0.5rem 1.5rem;
                    transition: color ease-in-out 300ms;

                    &:hover {
                        color: #ff9933;
                        text-decoration: underline;
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }

                ul.sub-menu {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    display: none;
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    background: #d11b6f;
                    color: #fff;
                    z-index: 999;
                    font-size: 1rem;
                    width: 320px;
                    padding: 1rem;

                    li.menu-item {
                        display: block;
                        text-align: left;

                        a {
                            display: block;
                            color: #fff;
        
                            &:hover {
                                color: #322a71;
                                text-decoration: underline;
                            }
        
                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }

    }

    #header-submenu {
        display: none;
        background: #d11b6f;
        color: #fff;

        .btn-categoria {
            color: #fff;
            border: 1px solid #322a71;
            background-color: transparent;
            transition: background-color ease-in-out 300ms, border-color ease-in-out 300ms;

            &.active,
            &:hover {
                color: #fff;
                background-color: #9a144c;
                border-color: #9a144c;
            }

            h2 {
                font-size: 1.1rem;
                line-height: 1.2;
                font-weight: 500;
                text-transform: uppercase;
                color: #fff;
            }

            p {
                font-size: 1rem;
                font-weight: 300;
                color: #fff;
            }
            a {
                color: #fff;
                &:hover {
                    color: #322a71;
                    text-decoration: underline;
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }

        .menu-servicos {
            display: none;

            &.active {
                display: block;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                transition: opacity ease-in-out 1000ms;

                li.menu-servicos-item {
                    h3 {
                        font-size: 1.1rem;
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                    a {
                        color: #fff;
                        &:hover {
                            color: #322a71;
                            text-decoration: underline;
                        }
                        &:focus {
                            box-shadow: none;
                        }
                    }

                    ul.menu-servicos-subitens {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        font-size: 90%;

                        li {
                            line-height: 1.5;
                            margin-bottom: 7px;
                        }
                    }
                }
            }
        }

        a {

            color: #fff;
            text-decoration: none;
            transition: color ease-in-out 300ms;

            &:hover {
                color: #ff9933;
                text-decoration: underline;
            }

            &:focus {
                box-shadow: none;
            }
        }

    }

    .btn-header-cta {
        display: block;
        background: #ff9933;
        color: #fff;
        transition: background-color ease-in-out 300ms;
        animation: animate_pulse_laranja 1.5s infinite;

        small {
            line-height: 2;
            vertical-align: middle;
        }

        &:hover {
            background: #d11b6f;
            animation: animate_pulse_rosa 1.5s infinite;
        }

        &:focus {
            box-shadow: none;
        }
    }

    #open-menu-mobile {
        display: none;
    }

    #close-menu-mobile {
        display: none;
    }
    @media (max-width: 1290px) {
        .btn-header-cta {
            display: none;
        }
        #header-menu {
            ul.menu {
                li.menu-item {
                    a{
                        padding: 0.5rem 1rem;
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        #open-menu-mobile {
            display: inline-block;
        }

        #close-menu-mobile {
            display: block;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 888;
        }

        #header-menu {
            position: fixed;
            top: 0;
            right: -350px;
            display: block;
            width: 320px;
            padding: 3rem 2rem;
            background: #322a71;
            box-shadow: 0 0 10px 0 rgba(#000, 0.2);
            z-index: 9999;
            height: 100vh;

            ul.menu {
                display: block;

                li.menu-item {
                    display: block;
                    text-align: left;

                    a {
                        display: block;
                    }

                    ul.sub-menu {
                        display: none;
                        position: relative;
                        top: 0;
                        left: 0;
                        padding: 0;
                        padding-left: 1rem;
                        background: none;
                        width: 100%;

                        li.menu-item {
                            display: block;
                            text-align: left;

                            a {
                                display: block;
                                &:hover{
                                    color: #ff9933;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#banner {
    width: 100%;
    .banner-content {
        display: block;
        position: relative;
        width: 100%;
        color: #fff;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 280px;
        padding-bottom: 160px;
        border-bottom: 7px solid transparent;

        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin-bottom: -7px;
            background: linear-gradient(to bottom right, #2c2566, #cb1964);
        }

        .scroll {
            display: block;
            width: 20px;
            height: 30px;
            border: 2px solid #cb1964;
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translate(-50%);
            text-indent: -999px;
            overflow: hidden;
            border-radius: 10px;

            ::before {
                content: '';
                width: 4px;
                height: 6px;
                border-radius: 2px;
                display: block;
                background: #cb1964;
                position: absolute;
                bottom: 5px;
                left: 50%;
                transform: translate(-50%);
                animation: animate_scroll 1s infinite;
            }
        }


        h1 {
            font-size: 5rem;
            font-weight: 500;
            color: #fff;
            line-height: 1.2;
        }

        h2 {
            font-size: 1.8rem;
            font-weight: 400;
            color: #fff;
            line-height: 1.2;
        }

        p {
            font-size: 1.2rem;
            color: #fff;
        }

        span {
            color: #d11b6f;
        }

        b {
            font-weight: 600;
        }
    }

    @media (max-width: 1200px) {
        .banner-content {
            background-position: center;
        }
    }

    @media (max-width: 767px) {
        .banner-content {
            padding-top: 130px;
        }
    }
}


.link-more {
    font-size: 1rem;
    font-weight: 600;
    color: #cb1964;
    text-decoration: none;

    &:hover,
    &:focus {
        color: #cb1964;
        text-decoration: none;
        box-shadow: none;

        i {
            animation-name: wobble_horizontal;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
        }
    }
}

.read-more {

    &:hover,
    &:focus {
        i {
            animation-name: wobble_horizontal;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
        }
    }
}

.block-about {
    background: #fff url('../images/bg-front-sobre.jpg') center no-repeat;
    background-size: auto 100%;
    padding: 80px 0;

    .block-about-title {
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 1.2;
        color: #322a71;

        span {
            color: #cb1964;
        }
    }

    .block-about-subtitle {
        font-size: 5.5rem;
        font-weight: 400;
        color: #322a71;
        line-height: 1;
        opacity: 0.5;
        mix-blend-mode: multiply;

        span {
            color: #cb1964;
        }

        @media (max-width: 767px) {
            font-size: 2rem;
            opacity: 0.8;

            br {
                display: none;
            }
        }
    }
}

.block-solucoes {
    padding: 80px 0;
    color: #fff;
    background-image: url('../images/bg-solucoes-categoria.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;

    @media (max-width: 991px) {
        background-position: left center;
    }

    p {
        color: #fff;
    }

    .solucao-title {
        position: relative;
        font-size: 2.8rem;
        font-weight: 600;
        margin: 0;
        line-height: 1.2;
        z-index: 3;        
    }

    .solucao-subtitle {
        position: relative;
        font-size: 1.4rem;
        font-weight: 400;
        margin: 0;
        right: -30px;            
        width: 100%;
        text-align: center;
        padding: 0 1rem 2rem;
        z-index: 2;
        @media (max-width: 767px) {
            right: 0px;    
        }

        &::before {
            content: '';
            width: 100%;
            height: 200px;
            border: 10px solid rgba(#000, 0.2);
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }

    a {

        color: #fff;
        text-decoration: none;
        transition: color ease-in-out 300ms;

        &:hover {
            color: #ff9933;
            text-decoration: underline;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &.block-solucoes-roxo {
        background-color: #322a71;

        .solucao-title {
            color: #fff;

            span {
                color: #cb1964;
            }
        }

        .solucao-subtitle {
            color: #fff;

            span {
                color: #cb1964;
            }
        }
        a {
            color: #fff;
            &:hover {
                color: #cb1964;
                text-decoration: underline;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }

    &.block-solucoes-rosa {
        background-color: #cb1964;

        .solucao-title {
            color: #fff;

            span {
                color: #322a71;
            }
        }

        .solucao-subtitle {
            color: #fff;

            span {
                color: #322a71;
            }
        }
        a {
            color: #fff;
            &:hover {
                color: #322a71;
                text-decoration: underline;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }

    .solucao-item {
        position: relative;
        z-index: 4;

        h3 {
            font-size: 1.2rem;
            font-weight: 600;
            text-transform: uppercase;
        }

        .solucao-subitens {
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.5;
            margin-bottom: 7px
        }
    }
}

.block-pilares{
    background-color: #322a71;
    color: #FFF;
    padding: 80px 0;
    background-image: url('../images/bg-pilares.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 991px) {
        background-position: left center;
    }
    .title-pilares{            
        color: #fff;
        font-weight: 500;
        font-size: 3.2rem;
        span {
            color: #cb1964;
        }
    }
    .pilares{
        .pilar-item{
            position: relative;
            min-width: 200px;            
            background-color: rgba(#322a71, 0.7);
            border: 1px solid lighten(#322a71, 10%);
            box-shadow: 0 0 10px 5px rgba(lighten(#322a71, 10%), 0.5);
            border-radius: 10px;
            .count-pilar-item{
                font-size: 3.5rem;
                color: #cb1964;
                position: absolute;
                top: -1rem;
                left: 1rem;
            }
            .title-pilar-item{                
                font-size: 1.2rem;
                font-weight: 400;
                color: #fff;
            }
            .descricao-pilar-item{
                font-size: 0.9rem;
                line-height: 1.2;
                font-weight: 300;
                color: #fff;
            }
        }
    }

}

.wrap-testimonials {
    .carousel-testimonials {
        .avatar {
            display: block;
            margin: 0 auto 1rem;
            position: relative;
            width: 120px;
            height: 120px;
            padding: 0;

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
            }
        }
    }
}
.wrap-partners {
    display: block;
    width: 100%;
    padding: 30px 0;
    background: #f5f5f5;
    background-size: cover;
    background-attachment: fixed;

    .title-partners {
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    .img-partner {
        filter: grayscale(1);
    }

    .carousel-partners {
        display: block;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
        padding: 0;
        position: relative;
        .owl-stage-outer {
            display: block;
            position: relative;
            overflow: hidden;
            z-index: 2;
        }

        .owl-nav {
            display: table;
            margin: 0 auto 0;
            padding-top: 0;
            z-index: 2;
        }

        .owl-nav button {
            cursor: pointer;
            margin: 0 10px;
            color: #d11b6f;
        }
    }
}
@media (max-width: 680px) {
    .wrap-testimonials .carousel-testimonials .owl-nav{
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }    
}

#footer-menu {
    display: block;
    .title-footer-menu{
        font-size: 1rem;
        color: #fff;
    }
    hr{ border-top: 1px solid rgba(255,255,255,0.5);}
    ul.menu {
        display: block;
        columns: 2;
        padding: 0;
        margin: 0;
        list-style: none;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 0.85rem;
        line-height: 1.2;

        li.menu-item {
            display: block;
            text-align: left;
            position: relative;

            a {
                display: inline-block;
                color: #fff;
                text-decoration: none;
                padding: 0.5rem 0;
                transition: color ease-in-out 300ms;

                &:hover {
                    color: #ff9933;
                    text-decoration: underline;
                }

                &:focus {
                    box-shadow: none;
                }
            }

            ul.sub-menu {
                display: block;
                padding-left: 1rem;
            }
        }
    }

    @media (max-width: 767px) {
        ul.menu {
            display: block;
            columns: unset;
            li.menu-item {
                display: block;
                text-align: left;
            }
        }
    }

}

@keyframes animate_pulse_laranja {
    0% {
        box-shadow: 0 0 0 0 rgba(#ff9933, 0.9);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(#ff9933, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(#ff9933, 0);
    }
}

@keyframes animate_pulse_rosa {
    0% {
        box-shadow: 0 0 0 0 rgba(#d11b6f, 0.9);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(#d11b6f, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(#d11b6f, 0);
    }
}

@keyframes animate_scroll {
    0% {
        bottom: 15px;
        background: rgba(#cb1964, 0);
    }

    100% {
        bottom: 5px;
        background: rgba(#cb1964, 1);
    }
}

@keyframes wobble_horizontal {
    16% {
        transform: translateX(6px);
    }

    33% {
        transform: translateX(-4px);
    }

    49% {
        transform: translateX(2px);
    }

    66% {
        transform: translateX(0px);
    }

    83% {
        transform: translateX(1px);
    }

    100% {
        transform: translateX(0);
    }
}